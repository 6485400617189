// https://www.youtube.com/watch?v=7rE_ew4GXLE

import React from "react"

import Seo from "../components/seo"
import Layout from "../components/layout"
import { useImpression } from "../analytics/utils"

const Criticisms = ({ location }) => {
  useImpression("Criticisms")
  return (
    <Layout location={location}>
      <Seo
        title="Criticisms of Religion"
        description="Religious critics often see it as outdated, harmful, and interfering with the progress of science. Let's explore some popular critiques of fath and religion."
      />
      <h1>Religious Criticisms</h1>
      <p>
        Let's be honest, it's pretty to easy to criticize religion. All of us
        could rewrite the Bible with a better moral code than the one it boasts.
        The Bible, in fact, not only allows slavery throughout Exodus, but it
        provides specific instructions about owning slaves! To think that this
        is the wisest book of all time is far from true.
      </p>
      <p>
        As an example, Christianity worships the idea of human sacrifice as a
        way to free us from our "original sin". Not to mention that original sin
        doesn't make sense in the new common belief that Adam and Eve are only a
        metaphor. Islam, on the other hand, writes about the Buraq. This is the
        mythical winged horse character found in the hadith, which flies
        Muhammad to and from Mecca.
      </p>
      <p>
        Besides the fact that religion has enough silly elements to make you
        skeptical, it also tends to have widespread impacts on our society as a
        whole. Here are a few examples.
      </p>
      <p>
        <strong>Religion encourages herd mentality</strong>: All religions claim
        that they are legitimate, and a majority of the world participates in
        it. Moreover, religion often entraps you at a very young age, even in
        infancy through baptisms. As you grow up you think the world around you
        is normal. Eating the body of Christ and drinking his blood doesn't even
        seem odd, let alone inhumane. Well, everyone else is doing it, so it
        can't be wrong, right? Wrong! Most non-religious people choose not to
        partake in the rituals and are perfectly happy with their lives.
      </p>

      <p>
        <strong>Diminishes the value in thinking for yourself</strong>: Religion
        makes us think we cannot be good without being following it. But when
        you start to give it up, you realize that you're exactly the same
        person. Some would argue that it makes you even more aware of your
        morality. Since you aren't spending time blindly worshiping a god, you
        can spend more time examining your actions and decisions. You can be
        more introspective, and not use God as an answer, or an excuse anymore.
      </p>
      <p>
        <strong>Ignores the reality of the world</strong>: Scientists are
        certainly hard at work seeking answers to why we're here, what the
        universe is all about, and if life exists beyond our planet, religion
        continues to ignore the awe-inspiring reality we have found ourselves
        in. The bible does not offer any explanations about the universe, or the
        origin of life, aside from fables.
      </p>
      <p>
        There are so many more reasons that religion just doesn't make sense.
        We're always finding more information, so be sure to bookmark this page
        to check back in the future.
      </p>
    </Layout>
  )
}

export default Criticisms
